import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'

const ContactSuccess = () => {
  return (
    <div className="c-alert-box c-alert-box--success contactFormSuccess">
      <p>
        <FormattedHTMLMessage id="contact.success-message" />
      </p>
    </div>
  )
}

export default ContactSuccess
