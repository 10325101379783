import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import * as Yup from 'yup'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Formik } from 'formik'
import Input from '../theming/Input'
import H2 from '../theming/H2'
import Textarea from '../theming/Textarea'
import Loader from './loader'
import { websiteApiClient } from '../utils/api'
import { useAgency } from '../hooks/use-agency'
import ContactSuccess from './contact-success'
import { useLocale } from '../hooks/use-locale'

const ContactSchema = Yup.object().shape({
  message: Yup.string()
    .min(2, 'Too Short!')
    .max(200, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  email: Yup.string()
    .email(<FormattedMessage id="This value is not a valid email address." />)
    .required(<FormattedMessage id="This value should not be blank." />),
  phone: Yup.string().required(
    <FormattedMessage id="This value should not be blank." />
  ),
})

const ErrorMessage = ({ message }) => (
  <span className="c-alert-inline c-alert-inline--error"> {message} </span>
)

const ContactForm = ({ intl, propertyId }) => {
  const locale = useLocale()
  const { agency } = useAgency()
  const { allProperty } = useStaticQuery(graphql`
    query {
      allProperty {
        nodes {
          id
          attributes {
            location {
              formatted_agency
            }
          }
        }
      }
    }
  `)

  // get the property the user is interested on
  const interestedProperty = allProperty.nodes.find(
    (property) => property.id === propertyId
  )

  const defaultMessage = interestedProperty
    ? intl.formatMessage(
        {
          id: 'placeholders.property-message.with-address',
        },
        {
          address: interestedProperty.attributes.location.formatted_agency,
        },
        {
          address:
            interestedProperty.attributes.location?.formatted_agency ?? '',
        }
      )
    : ''

  return (
    <Formik
      initialValues={{
        message: defaultMessage,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        property_id: interestedProperty?.id,
        locale,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        return websiteApiClient.post(`contact`, values).then(
          (success) => {
            setSubmitting(false)
            setStatus({ submitted: true })
          },
          (error) => {
            setSubmitting(false)
            setStatus({ submitted: false })
          }
        )
      }}
      validationSchema={ContactSchema}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        submitCount,
        status = { submitted: false },
      }) => {
        const submitted = submitCount > 0
        return (
          <form
            className="o-grid o-grid--gutter"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="o-grid__item u-3-of-5-bp4 u-push-1-of-5-bp4">
              <H2 className="section-title generalContactRequest">
                <FormattedMessage id="page.contact" />
              </H2>
              <H2
                className="section-title propertyContactRequest"
                style={{ display: 'none' }}
              >
                <FormattedMessage id="page.contact-property" />
              </H2>
              {isSubmitting && <Loader />}
              {status.submitted ? (
                <ContactSuccess />
              ) : (
                <div
                  className="contactFormFields"
                  style={{ display: isSubmitting ? 'none' : 'inherit' }}
                >
                  <div className="panel">
                    <div className="panel__body">
                      <div className="o-grid o-grid--gutter">
                        <div className="o-grid__item c-input-holder">
                          <label className="c-label" htmlFor="message">
                            <FormattedMessage id="message" />
                          </label>
                          <Textarea
                            className={classNames(
                              'c-input-text c-input-text--md',
                              {
                                'has-error': errors.message && submitted,
                              }
                            )}
                            id="message"
                            rows="3"
                            cols="30"
                            name="message"
                            autoFocus
                            value={values.message}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.message && submitted && (
                            <ErrorMessage message={errors.message} />
                          )}
                        </div>
                      </div>

                      <div className="o-grid o-grid--gutter">
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="name">
                            <FormattedMessage id="name" />
                          </label>
                          <Input
                            type="text"
                            className={classNames(
                              'c-input-text c-input-text--md',
                              {
                                'has-error': errors.first_name && submitted,
                              }
                            )}
                            name="first_name"
                            id="name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.first_name && submitted && (
                            <ErrorMessage message={errors.first_name} />
                          )}
                        </div>
                        <div className="o-grid__item u-1-of-2-bp3 c-input-holder">
                          <label className="c-label" htmlFor="familyName">
                            <FormattedMessage id="family-name" />
                          </label>
                          <Input
                            type="text"
                            className={classNames(
                              'c-input-text c-input-text--md',
                              {
                                'has-error': errors.last_name && submitted,
                              }
                            )}
                            name="last_name"
                            id="familyName"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.last_name && submitted && (
                            <ErrorMessage message={errors.last_name} />
                          )}
                        </div>
                      </div>
                      <div className="o-grid o-grid--gutter">
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="email">
                            <FormattedMessage id="email-address" />
                          </label>
                          <FormattedMessage id="placeholders.email-address">
                            {(placeholder) => (
                              <Input
                                type="email"
                                className={classNames(
                                  'c-input-text c-input-text--md',
                                  {
                                    'has-error': errors.email && submitted,
                                  }
                                )}
                                name="email"
                                id="email"
                                placeholder={placeholder}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            )}
                          </FormattedMessage>
                          {errors.email && submitted && (
                            <ErrorMessage message={errors.email} />
                          )}
                        </div>

                        <div className="o-grid__item u-1-of-2-bp3 c-input-holder">
                          <label className="c-label" htmlFor="phone">
                            <FormattedMessage id="phone-number" />
                          </label>
                          <FormattedMessage
                            id="placeholders.phone-number"
                            values={{
                              phone: '+32 XXX XXX XXX',
                            }}
                          >
                            {(placeholder) => {
                              return (
                                <Input
                                  type="tel"
                                  className={classNames(
                                    'c-input-text c-input-text--md',
                                    {
                                      'has-error': errors.phone && submitted,
                                    }
                                  )}
                                  name="phone"
                                  id="phone"
                                  placeholder={placeholder}
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )
                            }}
                          </FormattedMessage>
                          {errors.phone && submitted && (
                            <ErrorMessage message={errors.phone} />
                          )}
                        </div>
                        {/* <div className="o-grid__item">
                        <Recaptcha
                          ref="recaptcha"
                          sitekey={RECAPTCHA_KEY}
                          onChange={value => {
                            setFieldValue('g-recaptcha-response', value)
                          }}
                        />
                      </div> */}
                      </div>
                    </div>
                  </div>
                  {!isSubmitting && (
                    <p>
                      <FormattedMessage id="send">
                        {(value) => (
                          <Input
                            type="submit"
                            value={value}
                            className="c-button c-button--md c-button--alpha c-button--block"
                          />
                        )}
                      </FormattedMessage>
                    </p>
                  )}

                  <p className="u-color-neutral-base">
                    <small>
                      <FormattedHTMLMessage
                        id="contact.disclaimer"
                        values={{ name: agency.name }}
                      />
                    </small>
                  </p>
                </div>
              )}
            </div>
          </form>
        )
      }}
    />
  )
}

export default injectIntl(ContactForm)
