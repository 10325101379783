import React from 'react'

const Loader = () => (
  <p style={{ position: 'relative', padding: '10px' }}>
    <span className="pulse-loader">
      <span className="innerring" />
      <span className="innerdot" />
    </span>
  </p>
)

export default Loader
