import React from 'react'
import Layout from '../../components/layout'
import ContactForm from '../../components/contact-form'

export default (props) => {
  const propertyId = props.location.state?.propertyId
  return (
    <Layout {...props} title="page.contact">
      <div className="c-row c-row--beta">
        <div className="o-container">
          <ContactForm propertyId={propertyId} />
        </div>
      </div>
    </Layout>
  )
}
